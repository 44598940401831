article {
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem 0;
  border-bottom: 1px solid #ddd;
}

small {
  display: block;
}
