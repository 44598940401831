body {
  margin: 0;
  font-family: sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main {
  flex-grow: 1;
  background-color: lightcyan;
}
